(function ($) {
  Drupal.behaviors.heroCarouselFormatter = {
    attach: function (context) {
      var $formatter = $('.hero-carousel-formatter', context);

      // Loop through and init the carousels.
      // Carousels might contain variations, so dynamically change the settings before constructing slick
      $formatter.each(function () {
        var arrowsDiv = $(this).find('.carousel-controls');
        var dotsDiv = $(this).find('.carousel-dots');
        var heroSlide = '.js-hero-carousel__slide';
        var $carousel = $('.js-hero-carousel', this);
        var dotsColor = $formatter.data('dots-color');
        var arrowsImage = $formatter.data('arrows-image');
        var autoRotate = $formatter.data('auto-rotate') === 1;
        var enableDot = $formatter.data('dots-enable') === 1;
        var slideDuration = parseInt($formatter.data('slide-duration'));
        var carouselDots = $('.slick-dots').find('li');
        var settings = {
          appendArrows: arrowsDiv,
          autoplay: autoRotate,
          autoplaySpeed: slideDuration,
          dots: enableDot,
          slide: heroSlide,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                appendDots: dotsDiv,
                autoplay: autoRotate,
                autoplaySpeed: slideDuration,
                arrows: false,
                dots: enableDot,
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        };

        // Init this carousel with our settings
        $carousel.slick(settings);
        var slickDots = $(this).find('.slick-dots');

        if (dotsColor !== '') {
          $(
            '<style>.slick-dots li.slick-active button:before { color: ' +
              dotsColor +
              '; background: ' +
              dotsColor +
              ';}</style>'
          ).appendTo(slickDots);
        }
        if (arrowsImage !== '') {
          $formatter.find('.slick-arrow').css('background-image', 'url(' + arrowsImage + ')');
        }

        // Provides descriptive label for Carousel images
        carouselDots.each(function () {
          var slideId = $(this).attr('id');
          var slideImage = $(".js-hero-carousel__slide[aria-describedby='" + slideId + "']");
          var slickDotLabel = slideImage.find('img').attr('alt');

          $(this).find('button').attr('aria-label', slickDotLabel);
        });
      });
    }
  };
})(jQuery);
